import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CardMedia from '@material-ui/core/CardMedia';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import { getNfts } from '../utils/api'
import InfiniteScroll from 'react-infinite-scroll-component';

const useStyles = makeStyles((theme) => ({
  root: {

  },
  media: {
    height: 140,
    backgroundSize: 'contain'
  },
  nftGrid: {
    textAlign: 'left'
  },
  card: {
    cursor: 'pointer',
    height: '100%'
  },
  description: {
    maxWidth: '100%',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 3,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }
}));


function NftGrid(props) {
  const classes = useStyles()
  const { contractAddress } = props

  const [nfts, setNfts] = useState(null)
  const [nftPage, setNftPage] = useState(0)
  const [totalNftPages, setTotalNftPages] = useState(1)

  useEffect(() => {
    getNfts({ page: 0, contractAddress })
      .then((data) => {
        setNfts(data.nfts)
        setNftPage(data.page)
        setTotalNftPages(data.total_pages)
      })
  }, [contractAddress])

  const fetchNextPage = () => {
    getNfts({ page: nftPage + 1, contractAddress })
      .then((data) => {
        setNfts(oldNfts => [...oldNfts, ...data.nfts])
        setNftPage(data.page)
        setTotalNftPages(data.total_pages)
      })
  }

  return (
    <div className={classes.root}>
      {nfts
        ? (
          <InfiniteScroll
            dataLength={nfts.length}
            next={fetchNextPage}
            hasMore={nftPage < totalNftPages}
            loader={<p>Loading...</p>}
          >
            <Grid
              container
              spacing={3}
              className={classes.nftGrid}
              justifyContent="center"
              alignItems="stretch">
              {nfts.map(n => <Grid
                item
                key={`${n.id}`}
                lg={3}
                md={6}
                sm={12}
                data-nft-id={n.id}
              >
                <Link target='_blank' href={`https://opensea.io/assets/${n.contract_address}/${n.token_id}`}>
                  <Card className={classes.card}>
                    <CardMedia
                      className={classes.media}
                      image={n.image_url}
                      title={n.name}
                    />
                    <CardContent>
                      <Typography variant="h5" component="h2">
                        {n.name}
                      </Typography>
                      <Typography variant="body2" color="textSecondary" component="p" className={classes.description}>
                        {n.desc}
                      </Typography>
                      <div style={{ height: 8 }} />
                      <Typography variant='subtitle2' color="textSecondary">
                        Burned at {new Date(n.burned_at).toLocaleString()}
                      </Typography>

                    </CardContent>
                  </Card>
                </Link>
              </Grid>
              )}
            </Grid>
          </InfiniteScroll>
        ) : (
          <p>Loading...</p>
        )}
    </div>
  );
}

export default NftGrid;
