import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CardMedia from '@material-ui/core/CardMedia';
import CollectionGrid from './components/CollectionGrid'

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'center',
    maxWidth: 1280,
    marginRight: 'auto',
    marginLeft: 'auto'
  }
}));


function Home() {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <h1>The Museum of Burned NFTs</h1>
      <div style={{ height: 16 }} />
      <CollectionGrid />
    </div>
  );
}

export default Home;
