import { API_HOSTNAME } from '../config'

export const getNfts = async ({ page = 0, contractAddress }) => {
  const resp = await fetch(API_HOSTNAME + "/nfts?contract_address=" + contractAddress + "&page=" + page)
    .then(response => response.json())
  return resp
}

export const getNft = async ({ id }) => {
  const { nft } = await fetch(API_HOSTNAME + "/nfts/" + id)
    .then(response => response.json())
  return nft
}

export const getCollections = async ({ page = 0 }) => {
  const resp = await fetch(API_HOSTNAME + "/collections?page=" + page)
    .then(response => response.json())
  return resp
}

export const getCollection = async ({ id }) => {
  const { nft } = await fetch(API_HOSTNAME + "/collections/" + id)
    .then(response => response.json())
  return nft
}