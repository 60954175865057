import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CardMedia from '@material-ui/core/CardMedia';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import { getCollections } from '../utils/api'
import InfiniteScroll from 'react-infinite-scroll-component';
import { HOST } from '../config'
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
  },
  media: {
    height: 140,
    backgroundSize: 'contain'
  },
  nftGrid: {
    textAlign: 'left'
  },
  card: {
    cursor: 'pointer',
    height: '100%'
  },
  description: {
    maxWidth: '100%',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 3,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }
}));


function CollectionGrid() {
  const classes = useStyles()

  const [collections, setCollections] = useState(null)
  const [collectionPage, setCollectionPage] = useState(0)
  const [totalCollectionPages, setTotalCollectionPages] = useState(1)

  useEffect(() => {
    getCollections({ page: 0 })
      .then((data) => {
        setCollections(data.collections)
        setCollectionPage(data.page)
        setTotalCollectionPages(data.total_pages)
      })
  }, [])

  const fetchNextPage = () => {
    getCollections({ page: collectionPage + 1 })
      .then((data) => {
        setCollections(oldcollections => [...oldcollections, ...data.collections])
        setCollectionPage(data.page)
        setTotalCollectionPages(data.total_pages)
      })
  }

  return (
    <div className={classes.root}>
      {collections
        ? (
          <InfiniteScroll
            dataLength={collections.length}
            next={fetchNextPage}
            hasMore={collectionPage < totalCollectionPages}
            loader={<p>Loading...</p>}
          >
            <Grid
              container
              spacing={3}
              className={classes.nftGrid}
              justifyContent="center"
              alignItems="stretch"
              direction="row"
            >
              {collections.map(n => <Grid
                item
                key={`${n.id}`}
                lg={3}
                md={6}
                sm={12}
                data-nft-id={n.id}
              >
                <Link component={RouterLink} to={`/collection/${n.contract_address}`}>
                  <Card className={classes.card}>
                    <CardMedia
                      className={classes.media}
                      image={n.image_url}
                      title={n.name}
                    />
                    <CardContent>
                      <Typography variant="h5" component="h2">
                        {n.name}
                      </Typography>
                      <div style={{ height: 16 }} />
                      <Typography variant="body2" color="textSecondary" component="div" className={classes.description}>
                        {n.desc}
                      </Typography>
                    </CardContent>
                  </Card>
                </Link>
              </Grid>
              )}
            </Grid>
          </InfiniteScroll>
        ) : (
          <p>Loading...</p>
        )}
    </div>
  );
}

export default CollectionGrid;
